import {useState} from 'react'

export default function Search({cb=Function.prototype}) {
    const [value,setValue] = useState('');
    const handleSubmit=(e)=>{
        e.preventDefault();
        cb(value);
    }
    const handleEnter=(e)=>{
        e.preventDefault();
        if(e.key==='Enter'){
            cb(value);
        } 
    }
    return (
        <div className='row container'>
            <div className='input-field col s12'>
               <form  onSubmit={(e)=>(handleEnter(e))}  >
               <input 
                type='text'
                id='search-field'
                placeholder='Search'            
                onChange={(e)=>{
                   setValue(e.target.value);
                }}
                />
                <input
                className="btn blue lighten-3"
                type="submit"
                value="Search"
                onClick={(e)=>(
                    handleSubmit(e)
                )}        
                />
               </form>
            </div>
        </div>
    )
}
