import '../style/table.scss'
export default function Preloader(){
    return(
       <div className="preloader__item">
             <main>
             <div className="loaders">
             <div className="loader">
                <div className="loader-inner ball-grid-pulse">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                </div>
             </div>
            </div>
             </main>
       </div>
    )
}