import TextareaAutosize from 'react-textarea-autosize'
export default function InputTextarea({cb,nameV,defaultValue}){
    return(
    <TextareaAutosize
        rows={4}
        id="textarea1" 
        className="textAr hello" 
        name={nameV}
        placeholder='Заполните поле'
        onChange={(e)=>{
            cb(e)
        }}
        defaultValue={defaultValue}
        />
    )
}