

export default function IngItem({name,setName,id_}){
    const handleAdd=(e)=>{
        e.preventDefault();
        setName({
            id:id_,
            textIng:name
        })
    }
    return(
        <div className="row justify-content-center align-items-center">
                    <div className="col-sm-8 col-7 col-md-10">
                        <span>{name}</span>
                    </div>
                    <div className="col-sm-4 col-5 col-md-2">
                        <input 
                        className='btn blue lighten-1'
                        value='Выбрать'
                        type='submit'
                        onClick={(e)=>(
                           handleAdd(e)
                        )}
                        />
                    </div>
        </div>
    )
}