import '../style/menu.scss'
import {useState} from 'react'
import {useHistory,useLocation} from 'react-router-dom'
export default function MainPage(){
    const history = useHistory()
    const location = useLocation()
    return(
        <div className="main_page">
                            <span onClick={()=>{ history.push('/main')}}        className={location.pathname =='/main'?'activeC':''}       >Main Page    </span>                
                            <span onClick={()=>{ history.push('/statistics')}}  className={location.pathname =='/statistics'?'activeC':''} >Statistics   </span>
                            <span onClick={()=>{ history.push('/dishes/1')}}    className={location.pathname =='/dishes/1'?'activeC':''}   >Search Dishes</span>
                            <span onClick={()=>{ history.push('/recipes')}}     className={location.pathname =='/recipes'?'activeC':''}    >Recipes HSR   </span>
                            <span onClick={()=>{ history.push('/dishes-list')}} className={location.pathname =='/dishes-list'?'activeC':''}>Products HSR  </span>
        </div>
    )
}