import TextareaAutosize from 'react-textarea-autosize'

export default function InputAutoText({value_}){
    return(
        <TextareaAutosize
        rows={5}
        id="textarea1" 
        className="textarea-disabled" 
        value={value_}
        />
    )
}