import {useState,useEffect} from 'react'
import {getLimitations} from '../api'
export default function Preferences({setChoosePref=Function.prototype}){
    const emp = ''
    const[text,setText] =useState([])
    useEffect(()=>{
        getLimitations(setText)
    },[])
    const[ch, setCh] = useState({
        object:[]
    })
    const setState=(index)=>{
        setCh({object:[...ch.object,index]})
    }
const removeState=(index)=>{
    var i = ch.object.indexOf(index)
    ch.object.splice(i,1)
    setCh({object:ch.object})
}
    const toggle=(index)=>{
        if(!ch.object.includes(index)){
            setState(index)
            return;
        }
        else{
            removeState(index)
            return;
        }
    }
    const toggleAllow=(index)=>{
        if(ch.object.includes(index)) return true
        else return false
    }
    useEffect(()=>{
        setChoosePref(ch.object)
    },[ch])
   
    return(
        <div className="page__content__form">
                <div className="page__choose">
                    {
                    text? text.map((item,index)=>(
                        <div className="page__choose__content" key={item.id}>
                        <div 
                            onClick={()=>{toggle(item.id)}}
                            className={(toggleAllow(item.id)||emp.length!==0)?"image__content_select":"image__content"}>
                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 4.31925L3.42444 7.75022L10 1.18116L9.05597 0.250183L3.42444 5.87518L0.930956 3.38172L0 4.31925Z" fill="white"/>
                                </svg>
                        </div>
                        <div className="text_content">
                                <span>{item.name}</span>
                        </div>
                    </div>
                      )):null
                    }          
                </div>
                {/* <input type="submit" onClick={(e)=>(updateField(e))} className='btn btn-info' value='Select'/> */}
        </div>
    )
}