import InputText from '../component/InputText';
import InputPhone from '../component/InputPhone'
import {useState} from 'react'
import '../style/login.scss'
import {register} from '../api'
export default function Register(){
    const [userV, setUserV] = useState({
        name:'',
        surname:'',
        nickname:'',
        email:'',
        phone:'',
        birthdate:'',
        password_confirmation:'',
        password:'',
        gender:'',
        weight:'',
        height:''
})

  const changeName = (e)=>{
      e.preventDefault();
    setUserV({...userV,name:e.target.value})
  }
  const changeSurname = (e)=>{
    e.preventDefault();
  setUserV({...userV,surname:e.target.value})
}
// const changeLastname = (e)=>{
//     e.preventDefault();
//   setUserV({...userV,lastname:e.target.value})
// }
const changeNickname = (e)=>{
    e.preventDefault();
  setUserV({...userV,nickname:e.target.value})
}
const changePhone= (e)=>{
    e.preventDefault();
  setUserV({...userV,phone:e.target.value})
}
const changeEmail = (e)=>{
  e.preventDefault();
setUserV({...userV,email:e.target.value})
}
const changeHeight = (e)=>{
  e.preventDefault();
setUserV({...userV,height:e.target.value})
}
const changeWeight = (e)=>{
    e.preventDefault();
  setUserV({...userV,weight:e.target.value})
  }
  const changeGender = (e)=>{
    e.preventDefault();
  setUserV({...userV,gender:e.target.value})
  }
  const changePsw = (e)=>{
    e.preventDefault();
  setUserV({...userV,password:e.target.value})
  }
  const changePswCon = (e)=>{
    e.preventDefault();
  setUserV({...userV,password_confirmation:e.target.value})
  }
  const changeDate= (e)=>{
    e.preventDefault();
  setUserV({...userV,birthdate:e.target.value})
  }

function reg(e){
  e.preventDefault()
  register(
      userV.name,
      userV.surname,
      userV.nickname,
      userV.email,
      userV.phone,
      userV.birthdate,
      userV.password_confirmation,
      userV.password,
      userV.gender,
      userV.weight,
      userV.height,
      function(){}    
      )
}
return(
   <div>
      <div className='registerBody container'>
        <form onSubmit={reg}>
            <InputText placeholderText='name' cb = {changeName}/>
            <InputText placeholderText='surname' cb = {changeSurname}/>
            <InputText placeholderText='nickname' cb = {changeNickname}/>
            <InputText placeholderText='email' cb = {changeEmail}/>
            <InputPhone placeholderText='Введите номер телефона' cb = {changePhone}/>
            <InputText placeholderText='gender' cb = {changeGender}/>
            <InputText placeholderText='password' cb = {changePsw}/>
            <InputText placeholderText='password_confirmation' cb = {changePswCon}/>
            <InputText placeholderText='weight' cb = {changeWeight}/>
            <InputText placeholderText='height' cb = {changeHeight}/>
            <InputText placeholderText='birthdate' cb = {changeDate}/>
            <input className='btn' type="submit" value="Register"/>
        </form>
    </div>
   </div>
)
}