import '../style/filter.scss'
import Preloader from '../component/Preloader'
export default function WithCategories({list,getInfo=Function.prototype,putInfo}){
    const handleAddToList = (name) =>{
        if(putInfo.includes(name)){
            getInfo(putInfo.filter(x=>x!=name))
            return;
        }
        else{
            getInfo(putInfo=>[...putInfo,name])
        }
    }
    return(
        <div className="with_categories">
               {
                   list? list.map((p,pp)=>(
                        <div className="one_category" key={p.id} onClick={()=>(handleAddToList(p.id))}>
                            <div className={putInfo.includes(p.id)?"image_category box-shadow":"image_category"}>
                                    <img src={p.image} alt=""/>
                            </div>
                            <span>{p.name}</span>
                        </div>
                   )):<Preloader/>
               }
        </div>
    )
}