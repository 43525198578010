import '../style/table.scss'
export default function TableItem({talbeObject}){
    const state =  talbeObject?talbeObject:null;
    const names = [
        'Завтрак',
        'Перекус 1',
        'Обед',
        'Перекус 2',
        'Ужин',
        'Перекус 3'      
    ]
    console.log(state)
    return(
        <div className="table__item">
            {
           talbeObject? Object.keys(talbeObject).map((element,i)=>(
            <div className="element" key={element}>
                    {
                          Object.keys(talbeObject[element]).map((item,ii)=>(
                                 <div className="element__item" key={item}>
                                     {
                                        Object.keys(talbeObject[element][item]).map((el,iii)=>(
                                                <div className="element__item__el" key={el}>
                                                    <div className='heading'>
                                                        <span><strong>{names[iii]}</strong></span>
                                                    </div>
                                                    <div className="table__heading">
                                                        <span><i>ID</i></span>
                                                        <span><i>Name</i></span>
                                                    </div>
                                                    {
                                                        Object.keys(talbeObject[element][item][el]).map((ob,iiii)=>(
                                                                <div className="element__item__el__ob" key={ob}> 
                                                                    {
                                                                         Object.values(talbeObject[element][item][el][ob]).map((value,iiiii)=>(
                                                                             <span className="element__item__el__ob__value" key={iiiii}>
                                                                                     {value}
                                                                             </span>
                                                                         )) 
                                                                    }
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                            ))
                                    }
                          </div>
                            ))
                    }
            </div>
            )):null
            }
        </div>
    )
}