import '../style/recipes.scss'
export default function MicroModal({micros,closeModal=Function.prototype}){
    return(
        <div className="micro__modal" onClick={()=>(closeModal(false))}>
            <div className="micro__modal__content" onClick={(e)=>(e.stopPropagation())}>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Weight</th>
                    </tr>
                </thead>
                <tbody>
                   {
                       micros?micros.map((ids,key)=>{
                           return  <tr key={key}>
                                <td>{ids.name}</td>
                                <td>{ids.pivot.weight}</td>
                           </tr>
                       }):null
                   }
                </tbody>
            </table>
            </div>
        </div>
    )
}