import { useHistory } from 'react-router'
import back from '../image/back.svg'
import '../style/foodupdate.scss'
export default function Navbar(){
    const history = useHistory();
    const handle=(e)=>{
      e.preventDefault();
      history.push('/dishes/1')
    }
    return(
        <nav>
    <div className="nav-wrapper white">
      <ul>
        <li onClick={(e)=>(handle(e))} className='back-btn'>
            <img src={back} alt="back"/>
        </li>
      </ul>
    </div>
  </nav>
    )
}