function InputPassword(props){
    const {
        cb,
        placeholder
    } = props
    return(
        <input 
            type="password" 
            name="link"
            id='password'
            placeholder = {placeholder}
            onChange={(e)=>{
                    cb(e)
            }}
        />
    )
}
export default InputPassword;