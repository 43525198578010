import '../style/search.scss'
import {useEffect,useState} from 'react'
export default function CardFormula({formula,setRange=Function.prototype}){
    const[b,setB] = useState(1)
    const[m,setM] = useState(1)
  
    console.log(formula)
    useEffect(()=>{
          if(formula){
            setRange(Math.round(Number(formula.hsr_star_points/2)))
            if(formula.is_calculated ==true){
              setB(
                formula.baseline_energy_points + formula.baseline_sat_fat_points + formula.baseline_total_sugar_point + formula.baseline_sodium_point
            )
            setM(
                formula.modifying_point_fvnl +  formula.modifying_point_fibre +  formula.modifying_point_protein
            )
            }
          
          }
    },[])
    return(
        <div className="card__formula">
                <div className="card__formula__inside">
                <span>hrs_star_points =  10,499 - </span>
              <div className="card__formula__inside__content">
                <span>[ b - m ] - more</span>
                <div className='divline'></div>
                <span>range</span>
              </div>
                <span> * star_conversion_divisor</span>
                </div>
              {
                formula && formula.is_calculated==true?
                <div className="card__formula__inside">
                <span>{
                    formula ? Number(10.499) - ((Number(b)- Number(m)-Number(formula.fopl_calibration_category_more))/Number(formula.fopl_calibration_category_range))*Number(formula.star_conversion_divisor) :null
                    } =  10,499 - </span>
              <div className="card__formula__inside__content">
                <span>[ {b} - {m} ] - ( {formula?formula.fopl_calibration_category_more:null})</span>
                <div className='divline'></div>
                <span>{formula?formula.fopl_calibration_category_range:null}</span>
              </div>
                <span> * {formula?formula.star_conversion_divisor:null}</span>
                </div>
                :null
              }
        </div>
    )
}