import axios from 'axios'
import qs from 'qs'
const API_URL = 'https://api.nutristeppe.com/api/';
export const getAllDishes = async(id,text)=>{  
    if(window.localStorage.getItem("token_admin") != undefined){
        return axios.get(`${API_URL}admin/dishes?page=${id}&per_page=12&name=${text}`,
          {
            headers:{
                "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
            }
          }
        )
        
    }
  }

export const getDishByID = async(id,setInfo)=>{  
    if(window.localStorage.getItem("token_admin") != undefined){
    axios.get(`${API_URL}admin/dishes/${id}`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getMicro = async(setInfo)=>{
  if(window.localStorage.getItem("token_nom") != undefined){
    axios.get(`${API_URL}micronutrients`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res.data)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getDishCategories = async(setInfo)=>{
  if(window.localStorage.getItem("token_nom") != undefined){
    axios.get(`${API_URL}dish-categories`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res.data)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getRecipePerPageName = async(page,name,setInfo)=>{
  if(window.localStorage.getItem("token_nom") != undefined){
    axios.get(`${API_URL}recipes?page=${page}&per_page=5&name=${name}`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res.data)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getUnitTypes =(setInfo)=>{  
    if(window.localStorage.getItem("token_admin") != undefined){
    axios.get(`${API_URL}admin/unit-types`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getUnitTypesById = async(id)=>{  
  if(window.localStorage.getItem("token_admin") != undefined){
    return axios.get(`${API_URL}admin/unit-types/${id}`,
      {
        headers:{
            "x-requested-with": "xhr",
          "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
        }
      }
    )
    
}
}
export const getDayPlanMemo =()=>{  
  if(window.localStorage.getItem("token_nom") != undefined){
    return axios.get(`${API_URL}day-plan`,
      {
        headers:{
            "x-requested-with": "xhr",
            "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
        }
      }
    )
    
}
}
export const getComparedDishes =(id,setInfo)=>{  
  if(window.localStorage.getItem("token_admin") != undefined){
  axios.get(`${API_URL}admin/compare-dish-by-micros/${id}`,
        {
          headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
          }
        }
      )
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const getLimitations=(setInfo)=>{  
  if(window.localStorage.getItem("token_nom") != undefined){
    axios.get(`${API_URL}limitations`,
    {
      headers:{
          "x-requested-with": "xhr",
          "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
      }
    }
  )
  .then((res)=>{
      setInfo(res.data)
  })
  .catch((res)=>{
    console.log(res)
  });
}
}
export const getRecipePerPage = async(page,setInfo)=>{
  if(window.localStorage.getItem("token_nom") != undefined){
    axios.get(`${API_URL}recipes?page=${page}&per_page=5`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res.data)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getDayPlan =async(setInfo,p_id,d_id, prefs)=>{  
  if(window.localStorage.getItem("token_nom") != undefined){
    var str = ``
    for(let t = 0;t<prefs.length;t++){   
        str += `&limitations[${t}]=${prefs[t]}`
    }
  axios.get(`${API_URL}admin/plan-by-mask?purpose=${p_id}&day=${d_id}&${str.slice(1,str.length)}`,
        {headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
          }
        })
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const getRecipes = async(id,setInfo)=>{
  if(window.localStorage.getItem("token_nom") != undefined){
    axios.get(`${API_URL}dishes/${id}`,
    {headers:{
      "x-requested-with": "xhr",
      "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
    }
    })
        .then ((res)=>{ setInfo(res.data) })
        .catch((res)=>{ console.log(res)  })
    }
}
export const getNewDayPlan =async(setInfo)=>{  
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth()+1; 
  const yyyy = today.getFullYear();
  if(dd<10){
    dd = '0'+dd;
  }
  if(mm<10){
    mm='0'+mm
  }
  if(window.localStorage.getItem("token_nom") != undefined){
  axios.get(`${API_URL}day-plan?date=${dd}-${mm}-${yyyy}&new=1`,
        {headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
          }
        })
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const getRecommended=async(setInfo)=>{  
  if(window.localStorage.getItem("token_nom") != undefined){
  axios.get(`${API_URL}get-recommended-kilocalories`,
        {headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
          }
        })
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const getWeeklyPlan =async(setInfo)=>{  
  if(window.localStorage.getItem("token_nom") != undefined){
  axios.get(`${API_URL}weekly-plan`,
        {headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
          }
        })
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const getCookingTypes = async(setInfo)=>{  
    if(window.localStorage.getItem("token_admin") != undefined){
    axios.get(`${API_URL}admin/cooking-types`,
          {
            headers:{
                "x-requested-with": "xhr",
                "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
            }
          }
        )
        .then((res)=>{
            setInfo(res)
        })
        .catch((res)=>{
          console.log(res)
        });
    }
}
export const getPreferences = async(setInfo)=>{  
  if(window.localStorage.getItem("token_nom") != undefined){
  axios.get(`${API_URL}preferences`,
        {
          headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_nom")}`,
          }
        }
      )
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const getIng= async(id,text)=>{
    if(window.localStorage.getItem("token_admin") != undefined){
        return axios.get(`${API_URL}admin/ingredients?page=${id}&per_page=12&name=${text}`,
          {
            headers:{
                "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
            }
          }
        )
        
    }
}

export const updateTheDish=(
    id,
    name_,
    description_,
    recipe_link_,
    weight_,
    servings_,
    recipe_description_,
    ingredients_,
    callback
    )=>{    
    axios.put(`${API_URL}admin/dishes/${id}`,
    qs.stringify({
        name:name_,
        description:description_,
        recipe_link:recipe_link_,
        weight:weight_,
        servings:servings_,
        recipe_description:recipe_description_,
        ingredients:ingredients_
    }), 
     {
      headers:{
          "x-requested-with": "xhr",
          "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
      }
    },
    {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
    )
    .then((res)=>{ 
        callback(res)   
    })
    .catch((err)=>{
      alert('Заполните все необходимые поля');
        console.log(err)
    })
}

export const getByPurposeIdDay= async(setInfo,p_id,d_id)=>{  
  if(window.localStorage.getItem("token_admin") != undefined){
  axios.get(`${API_URL}admin/masks?purpose=${p_id}&day=${d_id}`,
        {
          headers:{
              "x-requested-with": "xhr",
              "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
          }
        }
      )
      .then((res)=>{
          setInfo(res.data)
      })
      .catch((res)=>{
        console.log(res)
      });
  }
}
export const updatePREF = (pref_ids,setStatus) => {
  axios.put(`${API_URL}user-profile/update-preference`,
      qs.stringify({
        preference_ids : pref_ids,
      }),{
        headers:{
        "x-requested-with": "xhr",
        "Authorization": `Bearer ${window.localStorage.getItem("token_admin")}`,
    }}
  )
  .then((res) =>{ setStatus(res.data)})
  .catch((err)=>{ console.log(err) })
}
export const login = (email_, password_,callback) => {
    axios.post(`${API_URL}login`,
        qs.stringify({
            email:email_,
            password: password_
        }),
        {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
    )
    .then((res)=>{
        window.localStorage.setItem("token_admin",res.data.token)
        window.localStorage.setItem("token_nom","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL25vbWFkaWV0Lmdpc3Qua3ovYXBpL3JlZ2lzdGVyIiwiaWF0IjoxNjIzMzE4OTM1LCJuYmYiOjE2MjMzMTg5MzUsImp0aSI6IldpbTBSOXZ2a3BIMzJZbDUiLCJzdWIiOjg0LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.OqfX7xK9OpvOQ_gIpF15AR6ssKGQC5neskmwai5BMPQ")
        // callback(res)
    })
    .catch((err)=>{
        console.log(err)
        callback(err)
    })
}
export const register  = ( 
    name_,
    surname_,
    nickname_,
    email_,
    phone_,
    birthdate_,
    password_confirmation_,
    password_,
    gender_,
    weight_,
    height_,
    callback ,
    error_callback
) => {
  axios.post(`${API_URL}register`,
      qs.stringify({
        name:name_,
        surname:surname_,
        nickname:nickname_,
        email:email_,
        phone:phone_,
        birthdate:birthdate_,
        password_confirmation:password_confirmation_,
        password:password_,
        gender:gender_,
        weight:weight_,
        height:height_,
        callback
      }),
      {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
  )
  .then((res)=>{
      window.localStorage.setItem("token_admin",res.data.token)
      callback(res)
  })
  .catch((err)=>{
     
  })
}
