import SearchPlan from "../component/SearchPlan"
import '../style/recipes.scss'
import '../style/style.scss'
import Filter from './Filter'
import CardSearch from "../component/CardSearch"
import {useEffect, useState} from 'react'
import Pagination from "../component/Pagination"
import {getRecipePerPage, getRecipePerPageName} from "../api"
import Preloader from '../component/Preloader'
import MainPage from "./MainPage"
export default function RecipePage(){
    const[recipes,setRecipes] = useState()
    const[currentPage,setCurrentPage] = useState(1)
    const[textSearch,setTextSearch] = useState('')
    const[recipeList,setRecipeList] = useState([])
    const[openModal,setOpenModal] = useState(false)
    const[total,setTotal] = useState(0)
    useEffect(()=>{
        getRecipePerPage(currentPage,setRecipes)
    },[currentPage])
    useEffect(()=>{
       if(recipes){
        setTotal(recipes.meta.total)
        setRecipeList(recipes.data)
       }
    },[recipes])
    useEffect(()=>{
        getRecipePerPageName(currentPage,textSearch,setRecipes)
    },[textSearch])
    console.log(textSearch)
    const onPageChanged = (currentPage)=>{
        if(currentPage.currentPage == undefined){
            return;
        }
        setCurrentPage(currentPage.currentPage)
    }   
    return(
        <div className="recipes__page">
            <MainPage/>
            <div className="plan_page__heading">
                <span>Рецепты</span>
            </div>
            <SearchPlan cb={setTextSearch}/>
            <div className="recipes__page__open__modal__button">
                <button onClick={()=>{setOpenModal(true)}}>Фильтры</button>
            </div>
            {
                openModal?<Filter closeModal={setOpenModal}/>:null
            }
            <div className="card__formula">
            <p>
            b= baseline_energy_points + baseline_sat_fat_points + baseline_total_sugar_point + baseline_sodium_point
           </p>
           <p>
            m = modifying_point_fvnl + modifying_point_fibre + modifying_point_protein
           </p>
           <p>
           range = fopl_calibration_category_range
           </p>
           <p>
               more = fopl_calibration_category_more
           </p>
            </div>
            <div className="recipes__page__itemsList">
            {
                recipeList && recipes ?
                recipeList.map((r,rr)=>{
                    return  <CardSearch 
                                key={r.id}
                                id={r.id}
                                productName={r.name}
                                productImage={r.image}
                                weight={r.weight}
                                kkals={r.kilocalories}
                                lights={r.lights}
                                healthFactor={r.health_factor}
                            />
                }):<Preloader/>
            }
            </div>
            {
                recipes?
                    <Pagination
                        totalRecords={2674}
                        pageLimit={5}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                        currentPage={currentPage}
                    />
                :null
            }
        </div>
    )
}