export default function HeaderWatch(){
    return(
        <thead>
            <tr>
                <th>Название</th>
                <th>Ед. изм.</th>
                <th>Количество</th>
                <th>Плотность</th>
                <th>Гр. на ед. измерения</th>
                <th>Способ приготовления</th>
                <th>% отхода</th>
                <th>коэф. термообработки</th>
                <th>Время приг.</th>
                <th>Масса брутто</th>
                <th>Масса нетто</th>
            </tr>
        </thead>
    )
}