// import '../assets/style/search.scss'
import {useState} from 'react'
export default function SearchPlan({cb=Function.prototype}){
    const[t,setT] = useState('')
    const handleSubmit=(e)=>{
        if(e.key=='Enter'){
            cb(t)
        }
        cb(t)
    }
    return(    
          <form className="search" onSubmit={(e)=>(handleSubmit(e))}>
            <input 
                    type="search" 
                    className="search__site" 
                    name="search"
                    placeholder='Искать по названию'
                    onChange={(e)=>(cb(e.target.value),setT(e.target.value))}
                />
                <button type='submit'>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5039 7.05078C11.2991 7.05078 11.1328 7.21703 11.1328 7.42188C11.1328 7.62672 11.2991 7.79297 11.5039 7.79297C11.7088 7.79297 11.875 7.62672 11.875 7.42188C11.875 7.21703 11.7088 7.05078 11.5039 7.05078Z" fill="#561BFB"/>
                        <path d="M18.5651 16.4661L13.6118 11.5128C14.4187 10.2973 14.8438 8.88974 14.8438 7.42188C14.8438 3.32945 11.5143 0 7.42188 0C3.32945 0 0 3.32945 0 7.42188C0 11.5143 3.32945 14.8438 7.42188 14.8438C8.88977 14.8438 10.2973 14.4187 11.5128 13.6118L12.8819 14.9809C12.8821 14.9811 12.8823 14.9813 12.8825 14.9815L16.4661 18.5651C16.7466 18.8456 17.1193 19 17.5156 19C17.912 19 18.2847 18.8456 18.565 18.5652C18.8455 18.2849 19 17.9122 19 17.5156C19 17.119 18.8455 16.7463 18.5651 16.4661ZM11.3383 12.8297C11.3383 12.8298 11.3382 12.8298 11.3382 12.8298C10.1927 13.6618 8.83853 14.1016 7.42188 14.1016C3.7387 14.1016 0.742188 11.1051 0.742188 7.42188C0.742188 3.7387 3.7387 0.742188 7.42188 0.742188C11.1051 0.742188 14.1016 3.7387 14.1016 7.42188C14.1016 8.83853 13.6618 10.1927 12.8298 11.3382C12.4135 11.9117 11.9117 12.4135 11.3383 12.8297ZM12.1168 13.1661C12.5009 12.8519 12.852 12.5008 13.1661 12.1168L14.219 13.1696C13.8988 13.5475 13.5475 13.8988 13.1696 14.219L12.1168 13.1661ZM18.0403 18.0403C17.9 18.1806 17.7137 18.2578 17.5156 18.2578C17.3175 18.2578 17.1312 18.1806 16.9909 18.0403L13.6958 14.7452C14.0716 14.4227 14.4227 14.0717 14.7451 13.6958L18.0404 16.9911C18.1806 17.1311 18.2578 17.3174 18.2578 17.5156C18.2578 17.7139 18.1806 17.9001 18.0403 18.0403Z" fill="#561BFB"/>
                        <path d="M7.42188 1.48438C4.1479 1.48438 1.48438 4.1479 1.48438 7.42188C1.48438 10.6958 4.1479 13.3594 7.42188 13.3594C10.6958 13.3594 13.3594 10.6958 13.3594 7.42188C13.3594 4.1479 10.6958 1.48438 7.42188 1.48438ZM7.42188 12.6172C4.55718 12.6172 2.22656 10.2866 2.22656 7.42188C2.22656 4.55718 4.55718 2.22656 7.42188 2.22656C10.2866 2.22656 12.6172 4.55718 12.6172 7.42188C12.6172 10.2866 10.2866 12.6172 7.42188 12.6172Z" fill="#561BFB"/>
                        <path d="M11.5805 5.82888C11.261 4.99719 10.7046 4.2861 9.97148 3.77254C9.2209 3.2467 8.33926 2.96875 7.42188 2.96875C7.21696 2.96875 7.05078 3.13493 7.05078 3.33984C7.05078 3.54476 7.21696 3.71094 7.42188 3.71094C8.94703 3.71094 10.3398 4.66903 10.8877 6.0951C10.9444 6.24265 11.0851 6.3332 11.2342 6.3332C11.2785 6.3332 11.3235 6.32522 11.3672 6.30841C11.5585 6.23489 11.6541 6.02022 11.5805 5.82888Z" fill="#561BFB"/>
                        </svg>
                </button>
          </form>
    )
}