import WeeklyMenu from "../component/WeeklyMenu";
import '../style/menu.scss'
import { Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination} from 'swiper';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss';
import Preloader from "../component/Preloader";
SwiperCore.use([Pagination]);
export default function Weekly({weekly,recom}){
  
    return(
        <div>
            <h4 style={{marginTop:'30px'}}>Recommended kilocalories: <strong>{recom}</strong></h4>
            {
        weekly? weekly.weekly_plan.map((item,index)=>(
         <div className='menu' key={index}>
            <h3>{item.day}</h3>
         <div className='swiper__content'>
             <div className="heading__name">
             <span>Завтрак</span>
             </div>
             <Swiper className="mySwiper"
                 pagination={{ clickable: true}}
             >
                 {
                    item.breakfast!=null? item.breakfast.map((el,index)=>{
                         return <SwiperSlide key={index}>
                             <div className='menu__content'>
                                 <WeeklyMenu
                                   productName={el.name}
                                   productImage={el.photo}
                                   lights={el.lights}
                                   weight={el.weight}
                                   kkals={el.kilocalories}
                                   link={'el.recipe_description'}
                                />
                             </div>
                         </SwiperSlide>
                     }):null
                 }
             </Swiper>
         </div>
         <div className='swiper__content'>
             <div className="heading__name">
                 <span>Обед</span>
             </div>
             <Swiper className="mySwiper"
                 pagination={{ clickable: true}}
             >
                 {
                    item.lunch!=null ? item.lunch.map((el,index)=>{
                         return <SwiperSlide key={index}>
                             <div className='menu__content'>
                                 <WeeklyMenu
                                 productName={el.name}
                                 productImage={el.photo}
                                 lights={el.lights}
                                 weight={el.weight}
                                 kkals={el.kilocalories}
                                 link={'el.recipe_description'}
                                />
                             </div>
                         </SwiperSlide>
                     }):null
                 }
             </Swiper>
         </div>
         <div className='swiper__content'>
             <div className="heading__name">
                 <span>Ужин</span>
             </div>
             <Swiper className="mySwiper"
                 pagination={{ clickable: true}}
             >
                 {
                   item.dinner!=null ? item.dinner.map((el,index)=>{
                         return <SwiperSlide key={index}>
                             <div className='menu__content'>
                                 <WeeklyMenu
                                    productName={el.name}
                                    productImage={el.photo}
                                    lights={el.lights}
                                    weight={el.weight}
                                    kkals={el.kilocalories}
                                    link={'el.recipe_description'}
                                />
                             </div>
                         </SwiperSlide>
                     }):null
                 }
             </Swiper>
         </div>
         </div>
         )):<Preloader/>
        }
    </div>
    )
}