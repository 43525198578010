import { useEffect, useState } from 'react'
import '../style/foodupdate.scss'
import {getDishByID, getUnitTypes, updateTheDish} from '../api'
import IngList from '../component/IngList'
import TypeIng from '../component/TypeIng'
import ChosenIngItem from '../component/ChosenIngItem';
import HeaderTable from '../component/HeaderTable';
import InputNumber from '../component/InputNumber'
import InputText from '../component/InputText'
import InputTextarea from '../component/InputTextarea'
import InputAutoText from '../component/InputAutoText'
import { useDispatch, useSelector } from 'react-redux';
import {actionType} from '../constants'
import {useHistory} from 'react-router-dom'
import Navbar from '../component/Navbar'

export default function FoodUpdate(props){
    const initial= {
        text : ''
    }
    const initial_of_ing = {
        ingredient_id:1,
        ingredient_name:'',
        unit_type_id:1,
        unit_type_amount:1, 
        gram_per_unit:1, 
        cooking_type_ids:1,
        cooking_time:1, 
        waste_percentage:1,
        gross_weight:1, 
        weight:1, 
        heat_ratio:1,
        density:1
    }
    const history = useHistory()
    const{ id } = props.match.params;
    const[volume, setVolume] = useState()
    const[getInfo, setInfo] = useState()
    const[addStep, setAddStep] = useState([]);
    var [chosenText,setChosenText] = useState();
    const[indices,setIndices] = useState([]);

    const[putInfo,setToPut] = useState(
        {
            name_:'',
            description_:'',
            recipe_link_:'',
            servings_:1,
            weight_:0,
            ingredients_:[],
            recipe_description_:[]
        }
    )
    const setDispatcher     = useDispatch();
    const ingredients       = useSelector((state) => state.reducer.initial_ing);
    const open              = useSelector((state) => state.reducer.opened);
    var   recipeOb          = useSelector((state) => state.reducer.recipe);
    const handleChangeFields=(e)=>{
        e.preventDefault();
        setToPut({...putInfo,[e.target.name]:e.target.value})
    }
    useEffect(()=>{
        getDishByID(id, setInfo)
        getUnitTypes(setVolume)
    },[])
    // useEffect(()=>{
    //     let ind = putInfo.recipe_description_.length   
    //     console.log(ind) 
    //         for(var i = ind;i<addStep.length;i++){
    //             putInfo.recipe_description_[i] = {text:addStep[i].text}
    //         }  
    // },[addStep]);
   
    useEffect(() => {
        if(getInfo == undefined) return;  
    if(putInfo.name_ ===''){
        if(getInfo){
            putInfo.name_ = getInfo.data.name
            putInfo.description_ = getInfo.data.description
            putInfo.servings_ = getInfo.data.servings
            putInfo.recipe_link_=getInfo.data.recipe_link
            putInfo.weight_=getInfo.data.weight
        }
    }
        let new_steps = []
        if(addStep.length<1){
            for(var q = 0;q < getInfo.data.recipe_description.length;q++){
                new_steps.push({ ...getInfo.data.recipe_description[q] , index : q})
                putInfo.recipe_description_.push(getInfo.data.recipe_description[q])
            }
        }
        setAddStep([
            ...new_steps
        ])
        let ingrs   = getInfo.data.ingredients
        let new_ar  = []

       if(getInfo){
        for(let x = 0;x < ingrs.length; x++){
          indices.push(x+1)
            new_ar.push({
                ingredient_id   :ingrs[x].id,
                ingredient_name :ingrs[x].name,
                unit_type_id    :ingrs[x].pivot.unit_type_id,
                unit_type_amount:ingrs[x].pivot.unit_type_amount, 
                gram_per_unit   :ingrs[x].pivot.gram_per_unit, 
                cooking_type_ids:ingrs[x].pivot.cooking_type_ids,
                cooking_time    :ingrs[x].pivot.cooking_time, 
                waste_percentage:ingrs[x].pivot.waste_percentage,
                gross_weight    :ingrs[x].pivot.gross_weight, 
                weight          :ingrs[x].pivot.weight, 
                heat_ratio      :ingrs[x].pivot.heat_ratio,
                density         :ingrs[x].pivot.density
            })
        }
       }
        setDispatcher({
            type    :   actionType.SET_ING,
            payload :   new_ar 
        })
    }, [getInfo])
      const handleAddSteps = ()=>{
          putInfo.recipe_description_ = [];
                for(var i = 0;i<addStep.length;i++){
                    putInfo.recipe_description_.push({text:addStep[i].text})
                }
            }
      
        const handleGram=(index,density_v,id_v)=>{
            var tot = 1;
          if(volume){
                for(var r = 0; r < 19;r++){
                    if(volume.data[r].id ==id_v){
                      tot = density_v * volume.data[r].volume;
                    }
                }
                ingredients[index] = {
                    ...ingredients[index],
                    gram_per_unit:tot
                }
                setDispatcher({
                    type    :   actionType.SET_ING,
                    payload :   ingredients 
                })
            }  
            return tot;
        }
        const handleBrutto=(index,gram_per,amout)=>{
            ingredients[index] = {
                ...ingredients[index],
                gross_weight:gram_per*amout
            }
            return gram_per*amout;
        }
        const handleAddIngId = (index)=>{
            setDispatcher({
                type    : actionType.SET_OPEN,
                payload :false
            })
            setDispatcher({
                type    : actionType.SET_INDEX,
                payload : index
            })
        }
        const handleNetto = (index,v1,v2,v3)=>{
            ingredients[index] = {
                ...ingredients[index],
                weight: v1*v2*v3,
            }
            return  v1*v2*v3;
        }
       const handleSaveTable=()=>{
          var temp_arr = new Array(indices.length);
          var temp_total = 0;
          for(var w = 0;w < ingredients.length; w++){
              if(ingredients[w].ingredient_id!=1){
                  let ind = indices[w]-1
                temp_arr[ind]={
                    ingredient_id:ingredients[w].ingredient_id,
                    unit_type_id:ingredients[w].unit_type_id,
                    unit_type_amount:ingredients[w].unit_type_amount, 
                    gram_per_unit:ingredients[w].gram_per_unit, 
                    cooking_type_ids:ingredients[w].cooking_type_ids,
                    cooking_time:ingredients[w].cooking_time, 
                    waste_percentage:ingredients[w].waste_percentage,
                    gross_weight:ingredients[w].gross_weight, 
                    weight:ingredients[w].weight, 
                    heat_ratio:ingredients[w].heat_ratio,
                    density:ingredients[w].density
                }
                temp_total += ingredients[w].weight;
              }
          }
          putInfo.ingredients_ = temp_arr
          putInfo.weight_ = temp_total
        }    
        const reg=(e)=>{
            e.preventDefault();
                updateTheDish( 
                    id,
                    putInfo.name_,
                    putInfo.description_,
                    putInfo.recipe_link_,
                    putInfo.weight_,
                    putInfo.servings_,
                    putInfo.recipe_description_,
                    putInfo.ingredients_,
                    function(res){
                        if(res.status==401){
                            alert('sdfgh')
                        }else{
                            history.push(`/dishes/view/${id}`); 
                           
                        }
                    })
                            
                }
                const handleRemove = (index) => {
                    setAddStep(addStep.filter(x => x.index != index))
                    // var arr = []
                    recipeOb=[]
                    for(let t = 0;t<addStep.length;t++){
                         recipeOb.push({text:addStep[t].text})
                         console.log(recipeOb[t])
                    }
                   
                    setDispatcher({
                        type    :   actionType.SET_RECIPE,
                        payload :   recipeOb
                    })
                    setToPut({...putInfo,recipe_description_:recipeOb}); 
                }  
                const handleAddRecipe=(e,index)=>{
                    addStep[index]={
                        index:addStep[index].index,
                        text:e.target.value
                    }
                    recipeOb[index]={
                        text:e.target.value
                    }
                    setDispatcher({
                        type    :   actionType.SET_RECIPE,
                        payload :   recipeOb
                    })
                    setToPut({...putInfo,recipe_description_:recipeOb}); 
                }     
   
    return(
        <div>
    <Navbar/>
    <div className='food-update'>
         <div className={open == false ?'popup-box':'none'} onClick={()=>(
               setDispatcher({
                type    : actionType.SET_OPEN,
                payload : true
            })
         )}>
                          <div className="box-move">
                          <div className='box'>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-12 col-md-10" onClick={(e)=>(e.stopPropagation())}>
                                                <IngList setChosenName={setChosenText}/>
                                            </div>
                                        </div>  
                            </div>
                          </div>
         </div>    
        <h4 className='h4'>{getInfo ? getInfo.data.name : null }</h4>
        <div className="row align-items-center">
        <div className="col-12 col-sm-12 col-md-4">
             <div className="image-content-div">
             <img src={getInfo? getInfo.data.image:null} alt="image" className='imageOfTheDish'/>
             </div>
            </div>
        <div className="col-12 col-sm-12 col-md-8">
           <div className="row onerow align-items-center padding">
                       <div className="grid-example col-2">
                           <span className="name">Bls-code:</span>
                       </div>
                       <div className="grid-example col-10">
                               <span className='chipCus'>{getInfo ? getInfo.data.bls_code : null }</span>
                       </div>
                </div>
                    <div className="row onerow align-items-center">
                        <div className="grid-example col-2">
                            <span className="name">Название:</span>
                        </div>
                       <div className="grid-example col-10">
                       <InputText 
                             defaultValue={getInfo ? getInfo.data.name : null}
                             name='name_'
                             cb={(e)=>( handleChangeFields(e))}
                        />
                       </div>
                   </div>
                    <div className="row onerow align-items-center">
                       <div className="grid-example col-2">
                           <span className="name">Описание:</span>
                       </div>
                       <div className="grid-example col-10">
                            <InputTextarea 
                            defaultValue={getInfo ? getInfo.data.description : null }
                            nameV='description'
                            cb={(e)=>(
                                 setToPut({...putInfo,description_:e.target.value})
                            )}/>
                       </div>
                   </div> 
                   <div className="row onerow align-items-center">
                       <div className="grid-example col-2">
                           <span className="name">Ссылка на рецепт:</span>
                       </div>
                       <div className="grid-example col-10">
                           <InputText 
                            defaultValue={getInfo ? getInfo.data.recipe_link : null }
                            name='description_link_'
                            cb={(e)=>( handleChangeFields(e))}
                           />
                       </div>
                   </div>
                   <div className="row onerow align-items-center padding">
                       <div className="grid-example col-2">
                           <span className="name">Категория:</span>
                       </div>
                       <div className="grid-example col-10">
                       {
                       getInfo? getInfo.data.category.map((el,index)=>{
                            return   <span key={index} className='chipCus'>{getInfo.data.category[index].name}</span>
                        }):null
                    }
                       </div>
                   </div>
                  <div className="row onerow align-items-center justify-content-start">
                       <div className="grid-example col-2">
                           <span className="name">Количество порций</span>
                       </div>
                       <div className="grid-example col-3">
                       <InputNumber 
                         defaultValue={getInfo ? getInfo.data.servings : null }
                         name='servings_'
                         cb={(e)=>( handleChangeFields(e))}
                        />
                       </div>
                   </div>
                   <div className="row onerow align-items-center justify-content-start">
                       <div className="grid-example col-2">
                           <span className="name">Вес</span>
                       </div>
                       <div className="grid-example col-3">
                       <InputAutoText value_={putInfo.weight_}/>
                       </div>
                   </div>
                   </div>
        </div>    
                    <div className="ing">
                        <h4 className='h4'>Продукты</h4> 
                       <table>
                          <HeaderTable/>
                          <tbody>
                           {
                               ingredients.map((el,index)=>{
                                   return  <tr key={el.ingredient_id}>
                                       <td>
                                           <InputNumber
                                            cb={(e)=>{
                                              indices[index]=Number(e.target.value)
                                            }} 
                                            defaultValue={indices[index]}
                                           />
                                       </td>
                                   <td> 
                                       <div onClick={()=>
                                          handleAddIngId(index)
                                           }>
                                            <InputAutoText 
                                            value_={ingredients[index].ingredient_name ? ingredients[index].ingredient_name :'Open'}/>
                                       </div>
                                   </td>
                                    <td> 
                                        <ChosenIngItem 
                                        cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                unit_type_id: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            })
                                        }}
                                        defaultValue={ingredients[index].unit_type_id}
                                        /> 
                                    </td>
                                     <td>
                                     <InputNumber cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                unit_type_amount: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            } )

                                        }} defaultValue={ingredients[index].unit_type_amount}/>                                    
                                     </td>
                                     <td>  
                                     <InputNumber cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                density: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            })

                                        }} defaultValue={ingredients[index].density}/>
                                    </td>
                                    <td>
                                        <InputAutoText value_={handleGram(index,ingredients[index].density,ingredients[index].unit_type_id)}/>
                                    </td>
                                     <td> 
                                         <TypeIng cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                cooking_type_ids: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            })

                                        }} defaultValue={ingredients[index].cooking_type_ids}/> </td>
                                     <td>
                                            <InputNumber cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                waste_percentage: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            })

                                        }} defaultValue={ingredients[index].waste_percentage}/>
                                     </td>
                                     <td>
                                         <InputNumber cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                heat_ratio: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            })

                                        }} defaultValue={ingredients[index].heat_ratio}/>
                                     </td>
                                     <td>
                                         <InputNumber cb={(e)=>{
                                            const new_ar = [...ingredients]
                                            new_ar[index] = {
                                                ...new_ar[index],
                                                cooking_time: e.target.value
                                            }
                                            setDispatcher({
                                                type    :   actionType.SET_ING,
                                                payload :   new_ar
                                            })

                                        }} defaultValue={ingredients[index].cooking_time}/>
                                     </td>
                                     <td>
                                        <InputAutoText value_={handleBrutto(index,ingredients[index].gram_per_unit,ingredients[index].unit_type_amount)}/>
                                     </td>
                                     <td>            
                                        <InputAutoText value_={handleNetto(index,ingredients[index].gross_weight,ingredients[index].waste_percentage,ingredients[index].heat_ratio)}/>
                                     </td>
                                     <td>   
                                            <button id="input_number" className='btn blue lighten-1' onClick={(e)=>{
                                               setDispatcher({
                                                   type     : actionType.SET_ING,
                                                   payload  : ingredients.filter(i => i.ingredient_id != ingredients[index].ingredient_id)
                                               })
                                               setIndices(indices.filter(i=>i != index+1))
                                                }}>Удалить</button>         
                                     </td>
                                </tr>
                               })
                           } 
                          </tbody>
                       </table>
                       <button id="input_number"  className='btn waves-effect waves-light blue' 
                       onClick={(e)=>{
                           setDispatcher({
                               type: actionType.SET_ING,
                               payload: [...ingredients, {
                                   ...initial_of_ing,
                                   ingredient_id: ingredients.length + 1
                                }]
                           })
                           setIndices(indices=>[...indices,indices.length+1])
                        }}>Добавить</button>
                         <button id="input_number" className='btn waves-effect waves-light green margin'  onClick={handleSaveTable()}
                         >Сохранить</button>
                    </div>
                    <h4 className='recipe'>Рецепт:</h4>
                   <div className="group-steps">
                 {
                  addStep.map((item,index)=>(                        
                        <div className="row onerow align-items-center justify-content-between" key={item.index}>
                        <div className="grid-example col-2">
                            <span className="name">Рецепт:(шаг {index+1})</span>
                        </div>
                        <div className="grid-example col-9">
                        <InputTextarea 
                            nameV='steps'                
                            defaultValue={item ? item.text : ""}    
                            cb={(e)=>{
                               handleAddRecipe(e,index)
                            }}     
                            />
                        </div>
                            <div className="col-1">
                            <a className="btn-floating btn-small waves-effect waves-light blue"
                                onClick={(e)=>{
                                  handleRemove(item.index)
                                }}><i className="material-icons">remove</i></a>
                            </div>
                        </div> 
                     ))
                 }
                  </div>
                  <div className="row">
                           <div className="col-2">
                           <button className="btn waves-effect waves-light blue"
                             onClick={(e)=>{
                                setAddStep(addStep=>[...addStep,{text:'',index:addStep.length+100}])
                                putInfo.recipe_description_.push({text:''})
                            }}>Добавить</button>   
                           </div>
                          <div className="col-2">
                                 
                             <button className="btn waves-effect waves-light green margin"
                             onClick={(e)=>{
                                handleAddSteps()
                            }}>Сохранить</button>  
                              </div>                                                   
                    </div>
                  <div className="row justify-content-center">
                      <div className="col-2">
                     
                     <input id="input_number" type="submit" value="Изменить блюдо" className='btn waves-effect waves-light btn-large red lighten-1' onClick={(e)=>(reg(e))}/>
                      </div>
                  </div>  
                </div>
                </div>
    )
}