import '../style/searchpage.scss'
import {getAllDishes} from '../api'
import {useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Search from '../component/Search'
import ItemTable from '../component/ItemTable'
import Pagination from '../component/Pagination';
import Preloader from '../component/Preloader'
import MainPage from './MainPage'

export default function SearchPage(props){
    const{
        id
    } = props.match.params

    var history = useHistory();
    var [arr,setArr] = useState([]);
    var [currentPage, setCurrentPage] = useState(parseInt(id))
    var [total, setTotal] = useState(0)
    var [search,setToSearch] = useState('');
    const onPageChanged = (currentPage)=>{
        if(currentPage.currentPage == undefined){
            history.push(`/dishes/${currentPage}`)
            return;
        }
        history.push(`/dishes/${currentPage.currentPage}`)
        setCurrentPage(currentPage.currentPage)
    }  
    useEffect(()=>{ 
            getAllDishes(currentPage,search).then((res) => {
                setArr(res.data.data)
                setTotal(res.data.meta.total)
            })    
},[currentPage,search]);
    return(
    <div className="search-page">
        <MainPage/>
        <div className="search-content">
            <div className=''>
                <div className="search-icon-div">
                    <div className="row">
                        <form className="col s5">
                            <div className="row justify-content-end">
                                <div className="input-field col s6">
                                    <Search cb={setToSearch}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="list-content">
                   <div className="list-headings">
                        <div className='row align-items-baseline heading'>
                           <div className="col-1"> <span>id</span></div>
                           <div className="col-2"> <span>Название</span> </div>
                           <div className="col-2"> <span>Белки</span></div>
                           <div className="col-2"> <span>Жиры</span></div>
                           <div className="col-1"> <span>Углеводы</span></div>
                           <div className="col-2"> <span>Категория</span></div>
                           <div className="col-2"> <span>Действия</span>  </div>     
                        </div>
                    </div>
                    <div>
                        {
                            arr.map((el,index)=>{
                                return <div key={index}>
                                        <ItemTable 
                                            id={arr[index].id}
                                            name={`${arr[index].name}`}
                                            fat={`${arr[index].macronutrients.fat}`}
                                            protein={arr[index].macronutrients.protein}
                                            carbohydrate={arr[index].macronutrients.carbohydrate}
                                            categories={arr[index].category}
                                        />
                                        </div>    
                            }) 
                            }
                    </div>  
                </div>
                <div>
                    {
                        total ? <Pagination
                                    totalRecords={total}
                                    pageLimit={arr.length}
                                    pageNeighbours={1}
                                    onPageChanged={onPageChanged}
                                    currentPage={parseInt(id)}
                        />
                        : <Preloader/>
                    }
                </div>
            </div>
        </div>
    </div>
)}